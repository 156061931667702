import React from 'react';

import {
  RESTRICTED_ACCESS,
  FULL_ACCESS,
  FULL_ACCESS_SALARYCHECK,
  NO_ACCESS,
  NO_ACCESS_REJECTED
} from './Statuses';

import { ROUTE_KEY_SALARY_CHECK } from '../../constants/index';

const Profile = React.lazy(() => import('../../pages/Profile'));
const PublicProfile = React.lazy(() => import('../../pages/PublicProfile'));
const MyTurtle = React.lazy(() => import('../../pages/MyTurtle'));
const Insights = React.lazy(() => import('../../pages/Insights'));
const Search = React.lazy(() => import('../../pages/Search'));
const Achievements = React.lazy(() => import('../../pages/Achievements'));
const SavedSearches = React.lazy(() => import('../../pages/SavedSearches'));
const SavedCandidates = React.lazy(() => import('../../pages/SavedCandidates'));
const ExcludedCandidates = React.lazy(() =>
  import('../../pages/ExcludedCandidates')
);
const Airfocus = React.lazy(() => import('../../pages/Airfocus'));
const SeafarerCandidateProfile = React.lazy(() =>
  import('../../pages/SeafarerCandidateProfile')
);
const SeafarerCV = React.lazy(() => import('../../pages/SeafarerCV'));
const SeafarerReducedCV = React.lazy(() => import('../../pages/SeafarerReducedCV'));
const Interviews = React.lazy(() => import('../../pages/Interviews'));
const Hires = React.lazy(() => import('../../pages/Hires'));
const Settings = React.lazy(() => import('../../pages/Settings'));
const NotAvailable = React.lazy(() => import('../../pages/NotAvailable'));
const NotFound = React.lazy(() => import('../../pages/NotFound'));
const PendingApproval = React.lazy(() => import('../../pages/PendingApproval'));
const Salarycheck = React.lazy(() => import('../../pages/SalaryCheck'));

const privateRoutes = [
  {
    key: 'my-turtle',
    component: MyTurtle,
    path: '/candidates',
    label: 'My TURTLE',
    exact: true,
    icon: 'uil-home-alt',
    isMenuLink: true,
    url: '/candidates',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'achievements',
    component: Achievements,
    path: '/achievements',
    label: 'Achievements',
    exact: true,
    icon: 'uil-trophy',
    isMenuLink: true,
    url: '/achievements',
    isTitle: false,
    notificationPoint: true,
    badge: { variant: 'danger', text: 'NEW' },
    permission: [...FULL_ACCESS]
  },
  {
    key: ROUTE_KEY_SALARY_CHECK,
    component: Salarycheck,
    path: '/salarycheck',
    label: 'Salarycheck',
    exact: true,
    icon: 'uil-dollar-sign',
    isMenuLink: true,
    url: '/salarycheck',
    isTitle: false,
    permission: [...FULL_ACCESS_SALARYCHECK]
  },
  {
    key: 'insights',
    component: Insights,
    path: '/insights',
    label: 'Insights',
    exact: true,
    icon: 'uil-graph-bar',
    isMenuLink: true,
    url: '/insights',
    isTitle: false,
    featureFlag: 'insightsPage'
  },
  {
    key: 'diversity',
    path: 'https://www.go-turtle.com/diversity',
    label: 'Diversity',
    exact: true,
    icon: 'uil-globe',
    isMenuLink: true,
    isMenuExternal: true,
    target: '_blank',
    url: 'https://www.go-turtle.com/diversity',
    isTitle: false,
    featureFlag: 'diversityPage'
  },
  {
    key: 'candidate-searches',
    label: 'Candidate Search',
    isTitle: true,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'search',
    component: Search,
    path: '/search',
    label: 'Search',
    exact: true,
    icon: 'uil-search-alt',
    isMenuLink: true,
    url: '/search',
    isTitle: false,
    notificationPoint: true,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'search-action',
    component: Search,
    path: '/search/:id/:mode',
    label: 'ViewEditSearch',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'saved-searches',
    component: SavedSearches,
    path: '/saved-searches',
    label: 'Saved Searches',
    exact: true,
    icon: 'uil-file-search-alt',
    isMenuLink: true,
    url: '/saved-searches',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'saved-candidates',
    component: SavedCandidates,
    path: '/saved-candidates',
    label: 'Saved Candidates',
    exact: true,
    icon: 'uil-user-plus',
    isMenuLink: true,
    url: '/saved-candidates',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'excluded-candidates',
    component: ExcludedCandidates,
    path: '/excluded-candidates',
    label: 'Excluded Candidates',
    exact: true,
    icon: 'uil-user-times',
    isMenuLink: true,
    url: '/excluded-candidates',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'recruitment',
    label: 'Recruitment',
    isTitle: true,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'contact-requests',
    component: Interviews,
    path: '/interviews',
    label: 'Contact Requests',
    exact: true,
    icon: 'uil-comments-alt',
    isMenuLink: true,
    url: '/interviews',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'hires',
    component: Hires,
    path: '/hires',
    label: 'Hires',
    exact: true,
    icon: 'uil-users-alt',
    isMenuLink: true,
    url: '/hires',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'account',
    label: 'Account',
    isTitle: true,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'profile',
    component: Profile,
    path: '/profile',
    label: 'Profile',
    exact: true,
    icon: 'uil-user-circle',
    isMenuLink: true,
    url: '/profile',
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'public-profile',
    component: PublicProfile,
    path: '/profile/public',
    label: 'Public Profile',
    exact: true,
    icon: '',
    isMenuLink: false,
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'seafarer-profile',
    component: SeafarerCandidateProfile,
    path: '/seafarer/:seafarerId',
    label: 'SeafarerCandidateProfile',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'seafarer-full-cv',
    component: SeafarerCV,
    path: '/seafarer/:seafarerId/printable-version',
    label: 'Seafarer Full CV',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'seafarer-reduced-cv',
    component: SeafarerReducedCV,
    path: '/seafarer/:seafarerId/reduced-cv',
    label: 'Seafarer CV',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'settings',
    component: Settings,
    path: '/settings',
    label: 'Settings',
    exact: true,
    isMenuLink: true,
    url: '/settings',
    isTitle: false,
    icon: 'uil-cog',
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'not-available',
    component: NotAvailable,
    path: '/not-available',
    label: 'Not Available',
    exact: true,
    icon: 'uil-phone-slash',
    isMenuLink: true,
    url: '/not-available',
    isTitle: false,
    permission: [...NO_ACCESS_REJECTED]
  },
  {
    key: 'pending-approval',
    component: PendingApproval,
    path: '/pending-approval',
    label: 'Pending Approval',
    exact: true,
    isMenuLink: true,
    url: '/pending-approval',
    isTitle: false,
    icon: 'uil-folder-question',
    permission: [...NO_ACCESS]
  },
  {
    key: 'candidate-profile',
    component: SeafarerCandidateProfile,
    path: '/job/:jobId/seafarer/:seafarerId',
    label: 'SeafarerCandidateProfile',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'candidate-full-cv',
    component: SeafarerCV,
    path: '/job/:jobId/seafarer/:seafarerId/printable-version',
    label: 'Seafarer Full CV',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'candidate-reduced-cv',
    component: SeafarerReducedCV,
    path: '/job/:jobId/seafarer/:seafarerId/reduced-cv',
    label: 'Seafarer CV',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'airfocus',
    component: Airfocus,
    path: '/airfocus',
    label: 'Airfocus',
    exact: true,
    isMenuLink: false,
    url: '/airfocus',
    isTitle: false,
    icon: 'uil-briefcase-alt',
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'not-found',
    component: NotFound,
    label: 'Not Found',
    isMenuLink: false,
    isTitle: false,
    permission: [
      ...RESTRICTED_ACCESS,
      ...FULL_ACCESS,
      ...NO_ACCESS,
      ...NO_ACCESS_REJECTED
    ]
  }
];

export default privateRoutes;
